import React, { useState } from "react";
import firebase from "./firebaseConfig";

function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Successfully signed up
        const user = userCredential.user;
        console.log("Successfully signed up:", user);
      })
      .catch((error) => {
        // Error during signup
        console.error("Failed to sign up:", error);
      });
  };

  return (
    <div className="signup-container">
      <h1>Sign Up</h1>
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSignup}>Sign Up</button>
    </div>
  );
}

export default SignupPage;
