import React from "react";
import gglogo from "./gglogo.png"; // Make sure the path is correct
import BlogPosts from "./BlogPosts"; // Import the BlogPosts component

function FeatureAnnouncementPage() {
  return (
    <div className="feature-announcement-container">
      <header className="App-header">
        <img src={gglogo} alt="GeekGenius Logo" className="App-logo-header" />
        <h1 className="App-title">GeekGenius Blog</h1>
      </header>
      <div className="blog-posts-section">
        <BlogPosts /> {/* This will display the blog posts */}
      </div>
    </div>
  );
}

export default FeatureAnnouncementPage;
