import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { format } from "date-fns";

function BlogPosts() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // New state for loading indicator

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true); // Start loading
      try {
        const querySnapshot = await getDocs(collection(db, "blogPosts"));
        const postsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const formattedDate = data.date
            ? format(data.date.toDate(), "yyyy-MM-dd")
            : "";
          return {
            ...data,
            id: doc.id,
            date: formattedDate,
            content: marked(data.content || ""),
          };
        });
        setPosts(postsData.filter((post) => post.content)); // Filter out posts without content
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false); // End loading
    };

    fetchPosts();
  }, []);

  const renderMarkdown = (markdown) => {
    const rawMarkup = marked(markdown);
    return DOMPurify.sanitize(rawMarkup);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (isLoading) {
    return <div className="loading-message">Loading...</div>; // Display loading message
  }

  return (
    <div>
      {posts.map((post) => (
        <div key={post.id} className="blog-post">
          <h2>{post.title}</h2>
          <p>{post.date}</p>
          <div
            dangerouslySetInnerHTML={{ __html: renderMarkdown(post.content) }}
          ></div>
        </div>
      ))}
    </div>
  );
}

export default BlogPosts;
