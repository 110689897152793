// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBP2gTA5JQ9VZRbYwd5-OSgJYe2ZdDte7M",
  authDomain: "geekgenius-cf2f0.firebaseapp.com",
  projectId: "geekgenius-cf2f0",
  storageBucket: "geekgenius-cf2f0.appspot.com",
  messagingSenderId: "421375112279",
  appId: "1:421375112279:web:8cc52a07f7dd571c78df12",
  measurementId: "G-Y4J2B60BZ7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore
// Optionally, export other Firebase services
//export const db = firebase.firestore();
//export const storage = firebase.storage();
export { auth, db, analytics };

export default app;
