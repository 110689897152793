import { useEffect } from "react";

function OtisRedirectPage() {
  useEffect(() => {
    // Redirect to the specified URL
    window.location.href =
      "https://drive.google.com/file/d/1dGyrO7ibmDlWF3BBr15_53ceAsckm7p-/view?usp=share_link";
  }, []);

  return null; // Return null as this component does not render anything
}

export default OtisRedirectPage;
