import { useEffect } from "react";

function OtisBloopRedirectPage() {
  useEffect(() => {
    // Redirect to the new URL
    window.location.href =
      "https://drive.google.com/file/d/1zz59iBzWexemY5Xfi0_RS0Ovq_DZHA6g/view?usp=share_link";
  }, []);

  return null; // This component does not render anything
}

export default OtisBloopRedirectPage;
