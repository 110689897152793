import React, { useState } from "react";
import firebase from "./firebaseConfig";

function LogonPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogon = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Successfully logged in
        const user = userCredential.user;
        console.log("Successfully logged in:", user);
      })
      .catch((error) => {
        // Error during login
        console.error("Failed to log in:", error);
      });
  };

  return (
    <div className="logon-container">
      <h1>Log On</h1>
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogon}>Log On</button>
    </div>
  );
}

export default LogonPage;
