import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import LogonPage from "./LogonPage";
import FeatureAnnouncementPage from "./FeatureAnnouncementPage";
import OtisRedirectPage from "./OtisRedirectPage"; // Import the new component
import OtisBloopRedirectPage from "./OtisBloopRedirectPage"; // Import the new redirect component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FeatureAnnouncementPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/logon" element={<LogonPage />} />
        <Route path="/otis" element={<OtisRedirectPage />} />{" "}
        <Route path="/otis-bloop" element={<OtisBloopRedirectPage />} />{" "}
        {/* Add the new route */}
      </Routes>
    </Router>
  );
}

export default App;
